import { marketTip, marketTipAddress, NUMBER_OF_DUMMY_UTXOS_TO_CREATE, DUMMY_UTXO_VALUE, MIN_UTXO_VALUE } from "../configs/constant";

export const generatePSBTListingInscriptionForSale = async (inscriptionId, wallet, price, handleOpen, refresh) => {
  const url = 'https://api.brc500.com/ordinal/generatePsbtForSale';
  const data = {
    inscriptionId: inscriptionId,
    ordinalAddress: wallet.nostrOrdinalsAddress,
    price: Number(price),
    provider: wallet.domain,
    pubkey: wallet.ordinalsPublicKey
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  response = await response.text();
  let signedPsbt;
  try{
    if (wallet.domain == "unisat") {
      signedPsbt = await window.unisat.signPsbt(response);
    }
    else if (wallet.domain == "okxwallet") {
      signedPsbt = await window.okxwallet.bitcoin.signPsbt(response);
    }
    signedPsbt = await getSignedPsbt(signedPsbt);
    await saveOrdinalSales(inscriptionId, price, signedPsbt)
    refresh();
  }
  catch(e)
  {
    console.log(e);
  }
  handleOpen(false);
}

export const delistOrdinals = async (inscriptionId) => {
  const url = 'https://api.brc500.com/ordinal/delistOrdinals';
  const data = {
    inscriptionId: inscriptionId
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

}

export const createDummyUtxos = async (utxos, ordinalsPublicKey, address, provider, showInfoMessage, showErrorMessage) => {

  let feeRate = await getFeeRate();
  let totalValue = 0
  let selectedUtxos = [];
  let cnt = 0;

  for (const utxo of utxos) {
    totalValue += utxo.value
    cnt += 1;
    selectedUtxos.push(utxo);

    if (totalValue > calculateFee(cnt, 3, feeRate) + NUMBER_OF_DUMMY_UTXOS_TO_CREATE * DUMMY_UTXO_VALUE) {
      break;
    }
  }

  const fee = calculateFee(cnt, 3, feeRate);

  const charge = totalValue - (NUMBER_OF_DUMMY_UTXOS_TO_CREATE * DUMMY_UTXO_VALUE) - fee;

  if (charge > 0 )
  {
    const url = 'https://api.brc500.com/ordinal/getDummyPsbt';
    const data = {
      utxos: selectedUtxos,
      ordinalsPublicKey: ordinalsPublicKey,
      address: address,
      charge: charge,
      provider: provider
    };

    let response  = await fetch(url, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    let psbt = await response.text();

    try {
      if (provider == "unisat")
      {
        let res = await window.unisat.signPsbt(psbt);
        res = await window.unisat.pushPsbt(res);
        //showInfoMessage(`Order successfully published! ${res}`);
      }
      if (provider == "okxwallet")
      {
        let res = await window.okxwallet.bitcoin.signPsbt(psbt);
        res = await window.okxwallet.bitcoin.pushPsbt(res);  
      }

    } catch (e) {
      console.log(e);
      //showErrorMessage(e.message);
    }
  }
  else
  {
    //showErrorMessage("Insufficient balance!");
  }
}

export const buyOrdinals = async (utxos, dummyUtxos, pubkey, address, sellerAddress, inscriptionId, sellerPsbt, price, provider, feeRate, tip, showInfoMessage, showErrorMessage) => {

  let cnt = 0;
  let selectedUtxos = [];
  let totalValue = 0;

  let newDummyUtxos = dummyUtxos.slice(0, 2);


  for (const utxo of utxos) {
    totalValue += utxo.value
    cnt += 1;
    selectedUtxos.push(utxo);

    if (totalValue > calculateFee(2 + cnt, 6, feeRate) + NUMBER_OF_DUMMY_UTXOS_TO_CREATE * DUMMY_UTXO_VALUE + price + tip) {
      break;
    }
  }

  console.log(utxos);
  console.log(totalValue);

  const fee = calculateFee(2 + cnt, 6, feeRate);

  console.log(fee);
  console.log(feeRate);

  const charge = totalValue - (NUMBER_OF_DUMMY_UTXOS_TO_CREATE * DUMMY_UTXO_VALUE) - fee - price - tip;

  if (charge > 0 )
  {

    const url = 'https://api.brc500.com/ordinal/buyOrdinals';
    // const url = 'http://95.216.55.187:3000/ordinal/buyOrdinals';
    const data = {
      selectedUtxos: selectedUtxos,
      dummyUtxos: newDummyUtxos,
      pubkey: pubkey,
      address: address,
      charge: charge,
      sellerPsbt: sellerPsbt,
      tip: tip,
      tipAddress: marketTipAddress,
      provider: provider
    };

    let response  = await fetch(url, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    let psbt = await response.text();

    try {
      if (provider == "unisat")
      {
        let res = await window.unisat.signPsbt(psbt);
        let txId = await window.unisat.pushPsbt(res);
        console.log("---txId-----", txId);
        saveBuyOrdinals(inscriptionId, price, address, sellerAddress);
  
        //setBuyTxId(txId);
        //toastr.info(`Order successfully published! ${txId}`);
        //showInfoMessage(`Order successfully published! ${txId}`);
      }
      if (provider == "okxwallet")
      {
        let res = await window.okxwallet.bitcoin.signPsbt(psbt);
        let txid = await window.okxwallet.bitcoin.pushPsbt(res);
        saveBuyOrdinals(inscriptionId, price, address, sellerAddress);  
      }

    } catch (e) {
      console.log(e);
      //toastr.error(e.message);
      //showErrorMessage(e.message);
    }
  }
  else
  {
    console.log("Insufficient balance!");
    //toastr.error("Insufficient balance!");
    //showErrorMessage("Insufficient balance!");
  }
}

const saveBuyOrdinals = async (inscriptionId, price, buyerAddress, sellerAddress) => {
  const url = 'https://api.brc500.com/saveOrdinalBuys';
  const data = {
    inscriptionId: inscriptionId,
    price: price,
    buyerAddress: buyerAddress,
    sellerAddress, sellerAddress
  };

  await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

const saveOrdinalSales = async (inscriptionId, price, signedPsbt) => {
  const url = 'https://api.brc500.com/saveOrdinalSales';
  const data = {
    inscriptionId: inscriptionId,
    price: price,
    psbt: signedPsbt
  };

  await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

}



const getSignedPsbt = async (signedPsbt) => {
  const url = 'https://api.brc500.com/ordinal/getSignedPsbt';
  const data = {
    signedPsbt: signedPsbt
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  response = await response.text();

  return response;
}

const getAddressUtxos =  async(address) => {
  let response = await fetch(`https://mempool.space/api/address/${address}/utxo`);
  return await response.json();
}

const getUnisatOrdinals = async(address) => {
  const url = 'https://wallet-api.unisat.io/v5/ordinals/inscriptions';
  const params = new URLSearchParams({
    address: address,
    cursor: 0,
    size: 100
  });

  const headers = new Headers();
  headers.append('X-Client', 'UniSat Wallet');
  headers.append('X-Version', "1.2.5");
  headers.append('x-address', address);
  headers.append('x-flag', 0 + '');
  headers.append('x-channel', "store");
  headers.append('x-udid', 'UkVxslRzcRkF');

  try {
    const response = await fetch(`${url}?${params}`, {
      method: 'GET', // GET is the default method and can be omitted.
      headers,
      mode: 'cors', cache: 'default'
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.data.list;
  } catch (e) {
    console.error('Fetch error:', e.message);
  }
}

const filterInscriptionUtxos = async (utxos, inscriptionUtxos) => {
  const newUtxos = [];
  for(let i=0;i<utxos.length;i++){   
    let result = await doesUtxoContainInscription(utxos[i], inscriptionUtxos);
    if (result == false) newUtxos.push(utxos[i]);
  }

  newUtxos.sort((a, b) => b.value -a.value);
  return newUtxos;
}

const doesUtxoContainInscription = async(utxo, inscriptionUtxos) => {
  for (const inscriptionUtxo of inscriptionUtxos) {
    if ( inscriptionUtxo.output == utxo.txid + ":" + utxo.vout) return true;
  }
  return false;
}

export const calculateFee = (vins, vouts, recommendedFeeRate, includeChangeOutput = 1 ) => {
  if (typeof recommendedFeeRate !== "number" || recommendedFeeRate <= 0)
    throw new Error("Invalid fee rate.");

  const baseTxSize = 10;
  const inSize = 57.5;
  const outSize = 43;
  const txSize = baseTxSize + vins * inSize + vouts * outSize + includeChangeOutput * outSize;
  const fee = Math.round(txSize * recommendedFeeRate);
  return fee;
}

const getFeeRate = async () => {
  const recommendedFeeRate = await fetch(`https://mempool.space/api/v1/fees/recommended`)
           .then(response => response.json())
           .then(data => data['fastestFee']) 
  return Math.round(recommendedFeeRate * 1.3);
}

export const getConfirmedUtxos = (utxos) => {
  const newUtxos = [];
  for (const utxo of utxos) {
    if (utxo.status != undefined && utxo.status.confirmed != undefined && utxo.status.confirmed == true) {
      newUtxos.push(utxo);
    }
  }
  return newUtxos;
}

export const getUtxos = async (address) => {
  let utxos = await getAddressUtxos(address);
  let inscriptionUtxos = await getUnisatOrdinals(address);
  utxos = await filterInscriptionUtxos(utxos, inscriptionUtxos);

  let dummyUtxos = [];
  let selectedUtxos = [];

  for(const uxo of utxos) {
    if (uxo.value >= MIN_UTXO_VALUE)
    {
      selectedUtxos.push(uxo);
    }
    else
    {
      dummyUtxos.push(uxo);
    }
  }
  return {selectedUtxos, dummyUtxos};
}

