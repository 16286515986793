import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

import { ToastContainer, toast } from 'react-toastify';
import SessionStorage, { SessionsStorageKeys } from "../services/session-storage";
import { setAddress } from '../store/action';
import { formatAddress } from '../util/format-data';
import { DocumentDuplicateIcon, ArrowRightEndOnRectangleIcon } from '@heroicons/react/24/solid';

import { getAddress } from 'sats-connect'

import 'react-toastify/dist/ReactToastify.css';
import '../custom-toast.css';
import { saveDataToStorage } from '../util/localstorage-util';


export default function Connect() {

  const wallet = useSelector(state => state.wallet);
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleOpen = (value) => {
    setShow(value);
  }

  const unisatWalletConnect = async() => {
    setShow(false);
    if (typeof window.unisat == 'undefined') {
      toast("Please install unisat wallet!" , {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: 'error-toast'
      });
    }
    else
    {
      try {
        SessionStorage.set(SessionsStorageKeys.DOMAIN, "unisat.io");
        let accounts = await window.unisat.requestAccounts();
        let address = await window.unisat.getAccounts();
        let pubkey = await window.unisat.getPublicKey();
        dispatch(setAddress({
            xWalletName : "unisat",
            domain : "unisat",
            nostrOrdinalsAddress : address[0].trim(),
            nostrPaymentAddress : address[0].trim(),
            ordinalsPublicKey : pubkey.trim(),
            paymentPublicKey : pubkey.trim()
        }));

        saveDataToStorage('unisat', address[0].trim());
        
        window.unisat.on('accountsChanged', handleUnisatAccountsChanged);
        return () => {
          window.unisat.removeListener('accountsChanged', handleUnisatAccountsChanged)
        }
      } catch(e) {
        console.log(e.response?.data?.message || e.message);
      }
    }
  }

  const handleUnisatAccountsChanged =  async (_accounts) => {
    if (_accounts.length > 0) {
      let address = await window.unisat.getAccounts();
      let pubkey = await window.unisat.getPublicKey();
      dispatch(setAddress({
        xWalletName : "unisat",
        domain : "unisat",
        nostrOrdinalsAddress : address[0].trim(),
        nostrPaymentAddress : address[0].trim(),
        ordinalsPublicKey : pubkey.trim(),
        paymentPublicKey : pubkey.trim()
      }));
      saveDataToStorage('unisat', address[0].trim());
    } else {
      dispatch(setAddress({
        xWalletName : "unisat",
        domain : "unisat",
        nostrOrdinalsAddress : "",
        nostrPaymentAddress : "",
        ordinalsPublicKey : "",
        paymentPublicKey : ""
      }));
      saveDataToStorage('', '');
    }
  }

  const xverseWalletConnect = async () => {
    setShow(false);
    const getAddressOptions = {
      payload: {
        purposes: ['ordinals', 'payment'],
        message: 'Address for receiving Ordinals and payments',
        network: {
          type:'Mainnet'
        },
      },
      onFinish: (response) => {
        console.log(response)
        if (response.addresses[0].purpose == "ordinals")
        {
          dispatch(setAddress({
            xWalletName : "xverseWallet",
            domain : "xverseWallet",
            nostrOrdinalsAddress : response.addresses[0].address.trim(),
            nostrPaymentAddress : response.addresses[1].address.trim(),
            ordinalsPublicKey : response.addresses[0].publicKey.trim(),
            paymentPublicKey : response.addresses[1].publicKey.trim()
          }));
          saveDataToStorage('xverseWallet', response.addresses[0].address.trim());
        }
        else
        {
          dispatch(setAddress({
            xWalletName : "xverseWallet",
            domain : "xverseWallet",
            nostrOrdinalsAddress : response.addresses[1].address.trim(),
            nostrPaymentAddress : response.addresses[0].address.trim(),
            ordinalsPublicKey : response.addresses[1].publicKey.trim(),
            paymentPublicKey : response.addresses[0].publicKey.trim()
          }));
          saveDataToStorage('xverseWallet', response.addresses[1].address.trim());
        }
      },
      onCancel: () => alert('Request canceled'),
      }
        
    await getAddress(getAddressOptions);
  }

  const handleXverseWalletAccountsChanged =  async (_accounts) => {
    if (_accounts.length > 0) {
      let address = await window.okxwallet.bitcoin.getAccounts();
      let pubkey = await window.okxwallet.bitcoin.getPublicKey();
      dispatch(setAddress({
        xWalletName : "xverseWallet",
        domain : "xverseWallet",
        nostrOrdinalsAddress : address[0].trim(),
        nostrPaymentAddress : address[0].trim(),
        ordinalsPublicKey : pubkey.trim(),
        paymentPublicKey : pubkey.trim()
      }));
    } else {
      dispatch(setAddress({
        xWalletName : "xverseWallet",
        domain : "xverseWallet",
        nostrOrdinalsAddress : "",
        nostrPaymentAddress : "",
        ordinalsPublicKey : "",
        paymentPublicKey : ""
      }));
    }
  }

  const okxWalletConnect = async () => {
    setShow(false);
    if (typeof window.okxwallet == 'undefined') {
      toast("Address copied!" , {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: 'error-toast'
      });
    }
    else
    {
      try {
        SessionStorage.set(SessionsStorageKeys.DOMAIN, "unisat.io");
        let accounts = await window.okxwallet.bitcoin.requestAccounts();
        let address = await window.okxwallet.bitcoin.getAccounts();
        let pubkey = await window.okxwallet.bitcoin.getPublicKey();
        dispatch(setAddress({
            xWalletName : "okxwallet",
            domain : "okxwallet",
            nostrOrdinalsAddress : address[0].trim(),
            nostrPaymentAddress : address[0].trim(),
            ordinalsPublicKey : pubkey.trim(),
            paymentPublicKey : pubkey.trim()
        }));

        saveDataToStorage('okxwallet', address[0].trim());
        
        window.okxwallet.bitcoin.on('accountsChanged', handleOkxWalletAccountsChanged);
        return () => {
          window.okxwallet.bitcoin.removeListener('accountsChanged', handleOkxWalletAccountsChanged)
        }
      } catch(e) {
        console.log(e.response?.data?.message || e.message);
      }
    }
  }

  const handleOkxWalletAccountsChanged =  async (_accounts) => {
    if (_accounts.length > 0) {
      let address = await window.okxwallet.bitcoin.getAccounts();
      let pubkey = await window.okxwallet.bitcoin.getPublicKey();
      dispatch(setAddress({
        xWalletName : "okxwallet",
        domain : "okxwallet",
        nostrOrdinalsAddress : address[0].trim(),
        nostrPaymentAddress : address[0].trim(),
        ordinalsPublicKey : pubkey.trim(),
        paymentPublicKey : pubkey.trim()
      }));
      saveDataToStorage('okxwallet', address[0].trim());
    } else {
      dispatch(setAddress({
        xWalletName : "okxwallet",
        domain : "okxwallet",
        nostrOrdinalsAddress : "",
        nostrPaymentAddress : "",
        ordinalsPublicKey : "",
        paymentPublicKey : ""
      }));
      saveDataToStorage('', '');
    }
  }

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value).then(
      () => {
        // Successfully copied to clipboard
        console.log('Address copied to clipboard');
        toast("Address copied!" , {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          className: 'my-toast'
          });
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy address: ', err);
      }
    );
  }

  const disconnectWallet = () => {
    dispatch(setAddress({
      xWalletName : "",
      domain : "",
      nostrOrdinalsAddress : "",
      nostrPaymentAddress : "",
      ordinalsPublicKey : "",
      paymentPublicKey : ""
    }));
    saveDataToStorage('', '');
  }

  return (
    <div>
      {
        wallet.nostrOrdinalsAddress != "" ?
          <div className="flex flex-row gap-2 items-center">
            <div className="flex flex-row gap-2 border-[1px] border-solid border-black rounded-md px-5 py-2 items-center">
              <DocumentDuplicateIcon className="h-5 w-5 cursor-pointer" onClick={() => {handleCopy(wallet.nostrOrdinalsAddress)}} />
              <span className="font-bold">{formatAddress(wallet.nostrOrdinalsAddress)}</span>
            </div>
            <div className="border-[1px] border-solid border-black rounded-md p-2">
              <ArrowRightEndOnRectangleIcon className="h-6 w-6 cursor-pointer" onClick={() => {disconnectWallet()}} />
            </div>
          </div>
          
          :
          <Button variant="outlined" className="text-[18px] py-2" onClick={() => handleOpen(true)}>Connect</Button>
      }
      <Dialog
        open={show}
        size={"sm"}
        handler={() => handleOpen(false)}
      >
        <DialogHeader>
          <div className="flex flex-row w-full justify-center mt-2 text-[28px] font-bold">Select Wallet</div>
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col gap-3 px-10">
            <div className="flex flex-row w-full items-center border-solid border-[1px] border-black rounded-lg hover:bg-[#F0F0F0] px-6 py-2 cursor-pointer" onClick={unisatWalletConnect}>
              <img src={"https://next-cdn.unisat.io/_/24/logo/color.svg"} className="w-[50px] h-[50px] bg-black p-2 rounded-md"/>
              <div className=" text-black font-semibold text-center text-[20px] w-full">Unisat Wallet</div>
            </div>
            <div className="flex flex-row w-full items-center border-solid border-[1px] border-black rounded-lg hover:bg-[#F0F0F0] px-6 py-2 cursor-pointer" onClick={xverseWalletConnect}>
              <img src={"https://next-cdn.unisat.io/_/24/img/wallet/xverse_icon_whitecolor.svg"} className="w-[50px] h-[50px] bg-black p-2 rounded-md"/>
              <div className=" text-black font-semibold text-center text-[20px] w-full">Xverse Wallet</div>
            </div>
            <div className="flex flex-row w-full items-center border-solid border-[1px] border-black rounded-lg hover:bg-[#F0F0F0] px-6 py-2 cursor-pointer" onClick={okxWalletConnect}>
              <img src={"https://next-cdn.unisat.io/_/24/img/wallet/okx_wallet_icon.svg"} className="w-[50px] h-[50px] bg-black p-2 rounded-md"/>
              <div className=" text-black font-semibold text-center text-[20px] w-full">OKX Wallet</div>
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={() => handleOpen(false)}
            className="py-[12px] mr-3"
          >
            <span>Cancel</span>
          </Button>
        </DialogFooter>
      </Dialog>
      <ToastContainer />
    </div>
  )
}
