import React, {useState, useEffect} from 'react'
import { 
  ButtonGroup, 
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography, 
  Textarea,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Spinner
} from "@material-tailwind/react";

import MyDeploys from './MyDeploys';
import MyMints from './MyMints';

export default function MyOrdinals() {
  const [mode, setMode] = useState("Deploy");

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row w-full justify-center">
        <ButtonGroup variant="gradient" className="gap-3">
          <Button onClick={() => {setMode("Deploy")}}>MTID Deploys</Button>
          <Button className="px-8" onClick={() => {setMode("Mint")}}>MTID Mints</Button>
        </ButtonGroup>
      </div>
      {
        mode == "Deploy" ? 
          <MyDeploys/>
          :
          <MyMints/>
      }
    </div>
  )
}
