import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  ButtonGroup, 
  Textarea,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Spinner
} from "@material-tailwind/react";

import FeeRateCard from '../FeeRateCard';
import { formatAddress } from '../../util/format-data';
import { generatePSBTListingInscriptionForSale, getConfirmedUtxos, getUtxos, createDummyUtxos, buyOrdinals, calculateFee, delistOrdinals } from '../../util/ordinal';
import { getFeeRate } from '../../util/inscribe-util';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import '../../custom-toast.css';

import { marketTip, NUMBER_OF_DUMMY_UTXOS_TO_CREATE, DUMMY_UTXO_VALUE } from '../../configs/constant';

export default function DeployCard(props) {
  const wallet = useSelector(state => state.wallet);
  const {data, refresh} = props;
  const [show, setShow] = useState(false);
  const [price, setPrice] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [utxos, setUtxos] = useState([]);
  const [dummyUtxos, setDummyUtxos] = useState([]);
  const [mode, setMode] = useState(1);
  const feeRateTabs = ["Slow", "Normal", "Fast"];
  const [feeRateMode, setFeeRateMode] = useState("Normal");
  const [feerate, setFeerate] = useState(0);
  const [feeRates, setFeeRates] = useState({});
  const [feeValues, setFeeValues] = useState({});
  const [delistShow, setDelistShow] = useState(false);

  let tip = marketTip;

  if (data.price * 0.025 > marketTip)
    tip = Math.round(data.price * 0.025);
  
  const handleOpen = (value, _price = 0) => {
    if (value == false) setShowSpinner(false);
    setShow(value)
    setPrice(_price / 100000000)
  }

  const showErrorMessage = (message) => {
    toast(message , {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      className: 'error-toast'
      });
  }

  const showInfoMessage = (message) => {
    toast(message , {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      className: 'my-toast'
      });
  }

  const handleBuy = async () => {
    if (wallet.nostrPaymentAddress == "") {
      toast("Please connect wallet!" , {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: 'error-toast'
        });
    }
    else
    {
      const {selectedUtxos, dummyUtxos} = await getUtxos(wallet.nostrPaymentAddress);
      const confirmedUtxos = getConfirmedUtxos(selectedUtxos);
      const confirmedDummyUtxos = getConfirmedUtxos(dummyUtxos);

      setUtxos(confirmedUtxos);
      setDummyUtxos(confirmedDummyUtxos);

      if (dummyUtxos.length >= 2)
      {
        if (confirmedDummyUtxos.length >= 2) {
          // buy ordinals
          let response = await getFeeRate();
          setFeeRates(response);
          let response1 = calcFee(boostFee(response[feeRateMode]));
          setFeeValues(response1);
          setFeerate(boostFee(response[feeRateMode]));

          setMode(3)
          setShow(true);
        }
        else
        {
          setMode(2);
          setShow(true);
        }
      }
      else
      {
        // create dummy utxos
        setShow(true);
        setMode(1);
      }
    }
  }

  const handleCreateDummyUtxos = async () => {
    setShowSpinner(true);
    await createDummyUtxos(utxos, wallet.paymentPublicKey, wallet.nostrPaymentAddress, wallet.domain, showInfoMessage, showErrorMessage );
    setShowSpinner(false);
    setShow(false);
  }

  const boostFee = (value) => {
    return Math.floor(value * 1.1)
  }

  const calcFee = (value) => {
    let cnt = 0;
    let totalValue = 0;

    for (const utxo of utxos) {
      totalValue += utxo.value
      cnt += 1;
  
      if (totalValue > calculateFee(2 + cnt, 6, value) + NUMBER_OF_DUMMY_UTXOS_TO_CREATE * DUMMY_UTXO_VALUE + data.price + tip) {
        break;
      }
    }
  
    const fee = calculateFee(2 + cnt, 6, value) + NUMBER_OF_DUMMY_UTXOS_TO_CREATE * DUMMY_UTXO_VALUE;
    const total = fee + data.price + tip;

    return {
      price: data.price,
      networkFee: fee,
      serviceFee: tip,
      totalFee: total
    }
  }

  useEffect(() => {
    let response = calcFee(boostFee(feeRates[feeRateMode]));
    setFeeValues(response);
    setFeerate(boostFee(feeRates[feeRateMode]));
  }, [feeRateMode])

  const handleBuyOrdinal = async () => {
    setShowSpinner(true);
    await buyOrdinals(utxos, dummyUtxos, wallet.paymentPublicKey, wallet.nostrPaymentAddress, data.sellerAddress, data.inscriptionId, data.psbt, data.price, wallet.domain, feerate, tip, showInfoMessage, showErrorMessage );
    setShowSpinner(false);
    setShow(false);
  }

  const handleDelist = async () => {
    setShowSpinner(true);
    await delistOrdinals(data.inscriptionId);
    setShowSpinner(false);
    refresh();
    setDelistShow(false);
  }

  return (
    <div>
      <Card className="w-[256px]">
        <CardBody>
          <div className="h-56 bg-black rounded-md flex flex-col justify-center items-center text-white"> 
            <div className="text-[28px] font-bold">MTID - Deploy</div>
            <div className="text-[24px] font-bold mt-3"><span>{data.t}</span></div>
            <div className="text-[20px]">#Mints:&nbsp;<span>{data.count}</span></div>
          </div>
          <div className="flex flex-col mt-2 text-black">
            <div className="font-bold">Owner: {formatAddress(data.owner)}</div>
            <div className=" font-semibold">Inscription #{data.inscriptionNumber}</div>
            <div className=" font-semibold">Price: {data.price != null ? data.price/100000000  : "-"}&nbsp;BTC</div>
          </div>
        </CardBody>
        <CardFooter className="pt-0">
          <div className="flex flex-row justify-center gap-3">
            {
              wallet.nostrOrdinalsAddress == data.owner ?
              <Button onClick = {() => setDelistShow(true)}>Delist</Button>
              :
              <Button onClick = {handleBuy}>Buy</Button>
            }
          </div>
        </CardFooter>
      </Card>
      <Dialog
        open={show}
        handler={() => handleOpen(false)}
        size={"xs"}
      >
        <DialogBody>
          {
            mode == 1 ?
            <div className="flex flex-col gap-4 w-full items-center p-3">
              <div className="font-bold text-[28px] text-black">
                Create dummy utxos
              </div>
              <div>
                Your address does not have 2 dummy utxos. Please create dummy utoxs.
              </div>
              {
                showSpinner ? 
                  <Spinner className="h-12 w-12" />
                  :
                  <Button onClick={handleCreateDummyUtxos}>Create dummy utxos</Button>
              }
            </div> : <></>
          }
          {
            mode == 2 ?
            <div className="flex flex-col gap-4 w-full items-center p-3">
              <div className="font-bold text-[28px] text-black">
                Create dummy utxos
              </div>
              <div className=" font-semibold text-red-600 text-center">
                Plesae wait until create dummy utxos transaction confirmed.
              </div>
            </div> : <></>
          }
          {
            mode == 3 ?
            <div className="flex flex-col gap-4 w-full items-center p-3">
              <div className="font-bold text-[28px] text-black">
                Buy Mtids
              </div>
              <div className="mt-3">
                Select the network fee you want to pay:
              </div>
              <div className="grid grid-cols-3 gap-3 w-full">
                <FeeRateCard header={feeRateTabs[0]} rate={feeRates[feeRateTabs[0]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[0])}}/>
                <FeeRateCard header={feeRateTabs[1]} rate={feeRates[feeRateTabs[1]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[1])}}/>
                <FeeRateCard header={feeRateTabs[2]} rate={feeRates[feeRateTabs[2]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[2])}}/>
              </div>
              <div className="flex flex-col mt-3 w-full">
                <div className="flex flex-row justify-between md:px-10 px-3">
                  <div>Price:</div>
                  <div><span className="font-bold text-black">{feeValues["price"]}</span> sats</div>
                </div>
                <div className="flex flex-row justify-between md:px-10 px-3">
                  <div>Network fee:</div>
                  <div><span className="font-bold text-black">{feeValues["networkFee"]}</span> sats</div>
                </div>
                <div className="flex flex-row justify-between md:px-10 px-3">
                  <div>Service fee:</div>
                  <div><span className="font-bold text-black">{feeValues["serviceFee"]}</span> sats</div>
                </div>
                <div className="flex flex-row justify-between md:px-10 px-3 border-solid border-t-blue-gray-400 border-[1px]">
                </div>
                <div className="flex flex-row justify-between md:px-10 px-3">
                  <div>Total:</div>
                  <div><span className="font-bold text-black">{feeValues["totalFee"]}</span> sats</div>
                </div>
              </div>
              {
                showSpinner ? 
                  <Spinner className="h-12 w-12" />
                  :
                  <Button onClick={handleBuyOrdinal}>Buy</Button>
              }
            </div> : <></>
          }
        </DialogBody>
      </Dialog>
      <Dialog
        open={delistShow}
        handler={() => setDelistShow(false)}
        size={"xs"}
      >
        <DialogBody>
          <div className="flex flex-col items-center gap-4 p-4">
            <div className="text-[24px] font-bold text-blue-800">Are you sure delist MTIDS?</div>
            {
              showSpinner ? 
                <Spinner className="h-12 w-12" />
                :
                <div className="flex flex-row gap-6 mt-4">
                  <Button className="px-10" onClick={handleDelist}>OK</Button>
                  <Button onClick={() => setDelistShow(false)}>Cancel</Button>
                </div>
            }
          </div>
        </DialogBody>
      </Dialog>
      <ToastContainer />
    </div>
  )
}
