export const encodedAddressPrefix = 'main';
export const padding = 546;
export const tip = 2999;
export const tippingAddress = 'bc1pg4jvczlqkes98g7gk2kl9sum6cqy8v6ymv2nrv80mkktjkzka3zscu5aqc';
export const mempoolNetwork = '';
export const include_mempool = true;
export const marketTip = 2000;
export const marketTipAddress = 'bc1pknjz4a5f709kq5x7xtjcr4fmhqqk3ck670kfhvaxg9htgz68djvs83wsey';
export const NUMBER_OF_DUMMY_UTXOS_TO_CREATE = 2;
export const DUMMY_UTXO_VALUE = 600;
export const MIN_UTXO_VALUE = 1000;