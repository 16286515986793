import React, {useState, useEffect} from 'react'
import { 
  ButtonGroup, 
  Button,
  Typography,
  Card
} from "@material-tailwind/react";
import { v4 as uuidv4 } from 'uuid';

export default function MintBuy() {
  const mintHead = ["BUYER", "MTID", "RANK", "PRICE", "DATE"];
  const [latestMintSales, setLatestMintSales] = useState([]);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [all, setAll] = useState(0);
  const [total, setTotal] = useState(0);

  const fetchData = async (p) => {
    let url = "";
    if (p == 1)
      url = `https://api.brc500.com/sales/mint?offset=&limit=${limit}`;
    else
      url = `https://api.brc500.com/sales/mint?offset=${p}&limit=${limit}`;

    // Use fetch to send the request

    let result = await fetch(url);
    let data = await result.json();

    setAll(data.total);
    setTotal(Math.ceil(data.total / limit))
    setLatestMintSales(data.data);
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {mintHead.map((head) => (
              <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {latestMintSales.map((data, index) => {
            const isLast = index === latestMintSales.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

            return (
              <tr key={uuidv4()}>
                <td className={classes}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {data.buyerAddress}
                  </Typography>
                </td>
                <td className={`${classes} bg-blue-gray-50/50`}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {data.t}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {data.position}
                  </Typography>
                </td>
                <td className={`${classes} bg-blue-gray-50/50`}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {data.price}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {data.date}
                  </Typography>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  )
}

