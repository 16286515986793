import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  ButtonGroup, 
  Textarea,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Spinner
} from "@material-tailwind/react";

import { formatAddress } from '../../util/format-data';
import { generatePSBTListingInscriptionForSale } from '../../util/ordinal';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import '../../custom-toast.css';

export default function MintCard(props) {
  const wallet = useSelector(state => state.wallet);
  const { data, refresh } = props;
  const [show, setShow] = useState(false);
  const [price, setPrice] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  
  const handleOpen = (value, _price = 0) => {
    if (value == false) setShowSpinner(false);
    setShow(value)
    setPrice(_price / 100000000)
  }

  const handleSale = () => {
    if (price == 0)
    {
      handleOpen(false);
      toast("Please insert price!" , {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: 'error-toast'
        });
    }
    else{
      setShowSpinner(true);
      generatePSBTListingInscriptionForSale(data.inscriptionId, wallet, Math.round(price * 100000000), handleOpen, refresh);
    }
  }
  return (
    <div>
      <Card className="w-[256px]">
        <CardBody>
          <div className="h-56 bg-black rounded-md flex flex-col justify-center items-center text-white"> 
            <div className="text-[28px] font-bold">MTID - Mint</div>
            <div className="text-[24px] font-bold mt-2"><span>{data.t}</span></div>
            <div className="text-[20px]">#Rank: <span>{data.position}</span></div>
            <div className="text-[20px]">#Mints: <span>{data.count}</span></div>
          </div>
          <div className="flex flex-col mt-2 text-black">
            <div className="font-bold">Owner: {formatAddress(data.owner)}</div>
            <div className=" font-semibold">Inscription #{data.inscriptionNumber}</div>
            <div className=" font-semibold">Price: {data.price != null ? data.price/100000000  : "-"}&nbsp;BTC</div>
          </div>
        </CardBody>
        <CardFooter className="pt-0">
          <div className="flex flex-row justify-center gap-3">
            <Button onClick = {() => {handleOpen(true, data.price)}} disabled = {data.price != null ? false : true}>Edit price</Button>
            <Button onClick = {() => {handleOpen(true)}} disabled = {data.price != null ? true : false}>List</Button>
          </div>
        </CardFooter>
      </Card>
      <Dialog
        open={show}
        handler={() => handleOpen(false)}
        size={"xs"}
      >
        <DialogBody>
          <div className="flex flex-col w-full items-center">
            <div className="h-56 md:w-[80%] w-56 bg-black rounded-md flex flex-col justify-center items-center text-white mt-5"> 
              <div className="text-[28px] font-bold">MTID - Mint</div>
              <div className="text-[24px] font-bold mt-2"><span>{data.t}</span></div>
              <div className="text-[20px]">#Rank: <span>{data.position}</span></div>
              <div className="text-[20px]">#Mints: <span>{data.count}</span></div>
            </div>
            <div className="flex flex-row gap-1 items-center mt-5 px-5">
              <div className="text-black">Price:</div>
              <Input
                label="price"
                value = {price}
                onChange = {(e) => {setPrice(e.target.value)}}
              />
              <div className="text-black">BTC</div>
            </div>
            {
              showSpinner ? 
                <div className="flex flex-row w-full justify-center mt-8">
                  <Spinner className="h-12 w-12" />
                </div>
                :
                <div className="flex flex-row w-full justify-center gap-5 mt-8">
                  <Button onClick = {() => {handleSale()}} className="px-10">List</Button>
                </div>
            }
          </div>
        </DialogBody>
      </Dialog>
      <ToastContainer />
    </div>
  )
}
