import React, {useState, useEffect} from 'react'
import { 
  ButtonGroup, 
  Button,

} from "@material-tailwind/react";

import MarketplaceDeploy from './MarketplaceDeploy';
import MarketplaceMint from './MarketplaceMint';
import DeployBuy from './DeployBuy';
import MintBuy from './MintBuy';

export default function MyOrdinals(props) {
  const {switchToMyProfile} = props;
  const [mode, setMode] = useState("Deploy");

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row w-full justify-center">
        <ButtonGroup variant="gradient" className="gap-3">
          <Button onClick={() => {setMode("Deploy")}}>Deploys Market</Button>
          <Button className="px-8" onClick={() => {setMode("Mint")}}>Mints Market</Button>
        </ButtonGroup>
      </div>
      {
        mode == "Deploy" ? 
          <MarketplaceDeploy switchToMyProfile = {switchToMyProfile}/>
          :
          <MarketplaceMint switchToMyProfile = {switchToMyProfile}/>
      }
      <div className = "mx-10 border-b-gray-400 border-[1px] border-solid mt-10">
      </div>
      <div className = "flex flex-col gap-5 h-[500px]">
        <div className="text-[28px] font-bold mt-5 flex flex-row justify-center">Latest Sales</div>
        <div className="grid md:grid-cols-2 grid-cols-1">
          <div className="flex flex-col gap-1">
            <div className="md:text-end md:mr-3 text-center font-bold text-[16px]">Deploys MARKET</div>
            <DeployBuy />
          </div>
          <div className="flex flex-col gap-1">
            <div className="md:text-start md:ml-3 text-center md:mt-0 mt-3 font-bold text-[16px]">Mints MARKET</div>
            <MintBuy />
          </div>
        </div>
      </div>
    </div>
  )
}