import React, {useState, useEffect, useCallback} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  MagnifyingGlassIcon
} from "@heroicons/react/24/outline";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Input,
  Select, 
  Option,
  ButtonGroup 
} from "@material-tailwind/react";

import DeployCard from './DeployCard';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import '../../custom-toast.css';


export default function MarketplaceDeploy(props) {
  const {switchToMyProfile} = props;
  const wallet = useSelector(state => state.wallet);
  const [ordinals, setOrdinals] = useState([]);
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState("o.price");
  const [orderDir, setOrderDir] = useState("DESC");
  const [all, setAll] = useState(0);
  const [total, setTotal] = useState(0);
  const [mtid, setMtid] = useState("");
  const [owner, setOwner] = useState("");
  const [ordinalDatas, setOrdinalDatas] = useState([]);
  
  const debouncedSearch = useCallback(debounce((value) => {
    fetchData(value, owner, 1);
    setPage(1);
  }, 300), []); 

  const handleSearch = (value) => {
    setMtid(value);
    debouncedSearch(value);
  };

  const fetchData = async (t, o, p) => {
    let url = "";
    if (p == 1)
      url = `https://api.brc500.com/ordinal/deploy?offset=&limit=${limit}&mtid=${t}&orderBy=${orderBy}&orderDir=${orderDir}`;
    else
      url = `https://api.brc500.com/ordinal/deploy?offset=${p}&limit=${limit}&mtid=${t}&orderBy=${orderBy}&orderDir=${orderDir}`;

    // Use fetch to send the request

    let result = await fetch(url);
    let data = await result.json();

    setAll(data.total);
    setTotal(Math.ceil(data.total / limit))
    setOrdinalDatas(data.data);
    console.log(data.data);
  }

  const next = () => {
    if (page === total) return;
 
    setPage(page + 1);
    fetchData(mtid, owner, page + 1);
  };

  const prev = () => {
    if (page === 1) return;
 
    setPage(page - 1);
    fetchData(mtid, owner, page - 1);
  };

  useEffect(() => {
    fetchData(mtid, wallet.nostrOrdinalsAddress, 1);
    setPage(1);
  }, [])

  useEffect(() => {
    if (wallet.nostrOrdinalsAddress != "")
    {
      setOwner(wallet.nostrOrdinalsAddress);
      fetchData(mtid, wallet.nostrOrdinalsAddress, 1);
      setPage(1);
    }
  }, [wallet.nostrOrdinalsAddress])

  useEffect(() => {
    setOwner(wallet.nostrOrdinalsAddress);
    fetchData(mtid, wallet.nostrOrdinalsAddress, 1);
    setPage(1);
  }, [orderDir])

  const handleSortChange = (event) => {
    setOrderDir(event);
  }

  const handleRefresh = () =>  {
    fetchData(mtid, owner, page);
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth' // Optional: defines the transition animation
    });
  };

  return (
    <div className="flex flex-col gap-4 mt-4">
      <div className="md:hidden flex justify-end flex-row gap-2 mb-3 items-center">
        <div className="font-bold text-[20px]">
          SORT BY:
        </div>
        <div className="max-w-[200px]">
            <Select size="md" value={orderDir} onChange={handleSortChange} >
              <Option value="DESC">Price Highest</Option>
              <Option value="ASC">Price Lowest</Option>
            </Select>
        </div>
      </div>
      <div className="flex flex-row md:justify-center justify-end w-full gap-2 items-center relative mb-4">
        <div className="font-semibold ml-9">MTID</div>
        <div className="max-w-[200px]">
          <Input
            label="Search"
            icon={<MagnifyingGlassIcon className="h-5 w-5" />}
            value = {mtid}
            onChange = {(e) => {handleSearch(e.target.value)}}
          />
        </div>
        <div className="md:flex hidden flex-row gap-2 mb-3 items-center absolute left-0 top-0">
          <div className="font-bold text-[20px]">
            SORT BY:
          </div>
          <div className="max-w-[200px]">
            <Select size="md" value={orderDir} onChange={handleSortChange} >
              <Option value="DESC">Price Highest</Option>
              <Option value="ASC">Price Lowest</Option>
            </Select>
          </div>
        </div>
        <div className="md:block hidden absolute right-0 top-0">
          <ButtonGroup variant="gradient" className="gap-3">
            <Button onClick={scrollToBottom}>Latest Sales</Button>
            <Button className="px-8" onClick = {switchToMyProfile}>My Mints</Button>
          </ButtonGroup>
        </div>
      </div>
      <div className="md:hidden flex justify-end flex-row">
        <ButtonGroup variant="gradient" className="gap-3">
          <Button >Latest Sales</Button>
          <Button className="px-8" onClick = {switchToMyProfile}>My Mints</Button>
        </ButtonGroup>
      </div>
      <div className="grid 3xl:grid-cols-6 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
        {ordinalDatas.map(
          (
            item,
            index,
          ) => {
            return (
              <div className="flex flex-row w-full justify-center" key={uuidv4()}>
                <DeployCard key={index} data={item} refresh = {handleRefresh}/>
              </div>
            );
          },
        )}
      </div>
      {
        total > 1 ? 
        <div className="flex flex-row justify-center items-center gap-8 mt-4">
          <IconButton
            size="sm"
            variant="outlined"
            onClick={prev}
            disabled={page === 1}
          >
            <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
          <Typography color="gray" className="font-normal">
            Page <strong className="text-gray-900">{page}</strong> of{" "}
            <strong className="text-gray-900">{total}</strong>
          </Typography>
          <IconButton
            size="sm"
            variant="outlined"
            onClick={next}
            disabled={page === total}
          >
            <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
        </div>
        :
        <></>
      }
      <ToastContainer />
    </div>
  )
}
