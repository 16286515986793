import React, { useState, useEffect } from 'react'
import { HomeIcon, PencilIcon, BuildingLibraryIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { 
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel 
} from "@material-tailwind/react";
import Deploy from './Indexer/Deploy';
import Mint from './Indexer/Mint';
import LatestMint from './Indexer/LatestMint';
import Connect from './Connect';
import Inscribe from './Inscribe';
import MyOrdinals from './MyOrdinals/MyOrdinals';
import Marketplace from './Marketplace/Marketplace';

export default function Indexer() {
  const tabs = ["Home", "Deploy/Mint","Watch my profile", "Marketplace"];
  const [active, setActive] = useState("Home");

  const handleActive = (index) => {
    setActive(tabs[index]);
  }

  const switchToMyProfile = () => {
    setActive(tabs[2]);
  }
  return (
    <div className="flex flex-col gap-5 md:p-10 p-5 bg-[#F0F0F0] min-h-[100vh] items-center w-full">
      <Typography variant="h1" color = "blue-gray">BRC-500 Explorer</Typography>
      <div className="flex flex-row gap-2 w-full justify-between items-center ">
        <div className="flex flex-row gap-2 items-center text-[20px] ">
          <div className={`flex flex-row gap-1 items-center hover:font-bold cursor-pointer py-1 px-2 ${active == tabs[0] ? 'font-bold border-solid border-gray-800 border-b-[2px]' : ''}`} onClick = {() => {handleActive(0)}}>
            <HomeIcon className="h-5 w-5" />
            <div className="md:flex hidden">{tabs[0]}</div>
          </div>
          <div className={`flex flex-row gap-1 items-center hover:font-bold cursor-pointer py-1 px-2 ${active == tabs[1] ? 'font-bold border-solid border-gray-800 border-b-[2px]' : ''}`} onClick = {() => {handleActive(1)}}>
            <PencilIcon className="h-5 w-5" />
            <div className="md:flex hidden">{tabs[1]}</div>
          </div>
          <div className={`flex flex-row gap-1 items-center hover:font-bold cursor-pointer py-1 px-2 ${active == tabs[2] ? 'font-bold border-solid border-gray-800 border-b-[2px]' : ''}`} onClick = {() => {handleActive(2)}}>
            <UserCircleIcon className="h-5 w-5" />
            <div className="md:flex hidden">{tabs[2]}</div>
          </div>
          <div className={`flex flex-row gap-1 items-center hover:font-bold cursor-pointer py-1 px-2 ${active == tabs[3] ? 'font-bold border-solid border-gray-800 border-b-[2px]' : ''}`} onClick = {() => {handleActive(3)}}>
            <BuildingLibraryIcon className="h-5 w-5" />
            <div className="md:flex hidden">{tabs[3]}</div>
          </div>
        </div>
        <Connect />
      </div>
      {
        active == tabs[0] ? 
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4 w-full">
            <Deploy/>
            <Mint/>
            <div className="md:col-span-2 col-span-1">
              <LatestMint/>
            </div>
          </div> :
          <></>
      }
      {
        active == tabs[1] ? 
          <div className="flex flex-row justify-center w-full">
            <Inscribe/>
          </div> :
          <></>
      }
      {
        active == tabs[2] ? 
          <MyOrdinals />
          :
          <></>
      }
      {
        active == tabs[3] ? 
          <Marketplace switchToMyProfile = {switchToMyProfile} />
          :
          <></>
      }
    </div>
  )
}
