import React, {useState, useEffect, useCallback} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  MagnifyingGlassIcon
} from "@heroicons/react/24/outline";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Input,
} from "@material-tailwind/react";

import MintCard from '../OrdinalCards/MintCard';


export default function MyDeploys() {
  const wallet = useSelector(state => state.wallet);
  const [ordinals, setOrdinals] = useState([]);
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState("m.inscriptionNumber");
  const [orderDir, setOrderDir] = useState("DESC");
  const [all, setAll] = useState(0);
  const [total, setTotal] = useState(0);
  const [mtid, setMtid] = useState("");
  const [owner, setOwner] = useState("");
  const [ordinalDatas, setOrdinalDatas] = useState([]);

  const debouncedSearch = useCallback(debounce((value) => {
    fetchData(value, owner, 1);
    setPage(1);
  }, 300), []); 

  const handleSearch = (value) => {
    setMtid(value);
    debouncedSearch(value);
  };
  
  const fetchData = async (t, o, p) => {
    let url = "";
    if (p == 1)
      url = `https://api.brc500.com/mint/mine?offset=&limit=${limit}&owner=${o}&mtid=${t}&orderBy=${orderBy}&orderDir=${orderDir}`;
    else
      url = `https://api.brc500.com/mint/mine?offset=${p}&limit=${limit}&owner=${o}&mtid=${t}&orderBy=${orderBy}&orderDir=${orderDir}`;

    // Use fetch to send the request

    let result = await fetch(url);
    let data = await result.json();

    setAll(data.total);
    setTotal(Math.ceil(data.total / limit))
    setOrdinalDatas(data.data);
    console.log(data.data);
  }

  const next = () => {
    if (page === total) return;
 
    setPage(page + 1);
    fetchData(mtid, owner, page + 1);
  };

  const prev = () => {
    if (page === 1) return;
 
    setPage(page - 1);
    fetchData(mtid, owner, page - 1);
  };

  // useEffect(() => {
  //   fetchData(mtid, wallet.nostrOrdinalsAddress, 1);
  //   setPage(1);
  // }, [])

  useEffect(() => {
    if (wallet.nostrOrdinalsAddress != "")
    {
      setOwner(wallet.nostrOrdinalsAddress);
      fetchData(mtid, wallet.nostrOrdinalsAddress, 1);
      setPage(1);
    }
  }, [wallet.nostrOrdinalsAddress])

  const handleRefresh = () =>  {
    fetchData(mtid, owner, page);
  }

  return (
    <div className="flex flex-col gap-4 mt-4 w-full">
      <div className="flex flex-row justify-center w-full gap-2 items-center">
        <div className="font-semibold ml-9">MTID</div>
        <div className="max-w-[200px]">
          <Input
            label="Search"
            icon={<MagnifyingGlassIcon className="h-5 w-5" />}
            value = {mtid}
            onChange = {(e) => {handleSearch(e.target.value)}}
          />
        </div>
      </div>
      <div className="grid 3xl:grid-cols-6 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
        {ordinalDatas.map(
          (
            item,
            index,
          ) => {
            return (
              <div className="flex flex-row w-full justify-center" key={uuidv4()}>
                <MintCard key={index} data={item} refresh = {handleRefresh} />
              </div>
            );
          },
        )}
      </div>
      {
        total > 1 ? 
        <div className="flex flex-row justify-center items-center gap-8 mt-4">
          <IconButton
            size="sm"
            variant="outlined"
            onClick={prev}
            disabled={page === 1}
          >
            <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
          <Typography color="gray" className="font-normal">
            Page <strong className="text-gray-900">{page}</strong> of{" "}
            <strong className="text-gray-900">{total}</strong>
          </Typography>
          <IconButton
            size="sm"
            variant="outlined"
            onClick={next}
            disabled={page === total}
          >
            <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
        </div>
        :
        <></>
      }
    </div>
  )
}
