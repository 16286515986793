import React, {useState, useEffect, useCallback} from 'react'
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { PencilIcon } from "@heroicons/react/24/solid";
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Input,
} from "@material-tailwind/react";

import { debounce } from 'lodash';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import '../../custom-toast.css';
const TABLE_HEAD = ["MTID", "OWNER", "Balance", "Rank of Mints"];

export default function Mint() {
  const wallet = useSelector(state => state.wallet);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [all, setAll] = useState(0);
  const [owner, setOwner] = useState("");
  const [mtid, setMtid] = useState("");
  const [limit, setLimit] = useState(10);
  const [mintDatas, setmintDatas] = useState([]);
 
  const next = () => {
    if (page === total) return;
 
    setPage(page + 1);
    fetchData(mtid, owner, page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
 
    setPage(page - 1);
    fetchData(mtid, owner, page - 1);
  };

  const debouncedSearch = useCallback(debounce((mod, value) => {
    if (mod == 0){
      fetchData(value, owner, 1);
    }
    else {
      fetchData(mtid, value, 1);
    }
    setPage(1);
  }, 300), []); 

  const handleSearch = (mod, value) => {
    if (mod == 0){
      setMtid(value);
    } else {
      setOwner(value);
    }
    debouncedSearch(mod, value);
  };

  const fetchData = async (t, o, p) => {
    let url = "";
    if (p == 1)
      url = `https://api.brc500.com/mint?offset=&limit=${limit}&owner=${o}&mtid=${t}`;
    else
      url = `https://api.brc500.com/mint?offset=${p}&limit=${limit}&owner=${o}&mtid=${t}`;

    // Use fetch to send the request

    let result = await fetch(url);
    let data = await result.json();

    setAll(data.total);
    setTotal(Math.ceil(data.total / limit))
    setmintDatas(data.data);

  }

  const handleClick = async (position, mtid) => {
    const url = `https://api.brc500.com/address?mtid=${mtid}&position=${position}`;

    // Use fetch to send the request

    let result = await fetch(url);
    let data = await result.json();

    // window.open(`https://ordinals.com/inscription/${data.inscriptionId}`, '_blank');
    // var link = window.document.createElement('a');
    // link.href = `https://ordinals.com/inscription/${data.inscriptionId}`;
    // link.target = '_blank';
    // window.document.body.appendChild(link);
    // link.click();
    // window.document.body.removeChild(link);
    window.location.href = `https://ordinals.com/inscription/${data.inscriptionId}`;
  }

  useEffect(() => {
    fetchData(mtid, owner, page)
  }, [])

  useEffect(() => {
    setOwner(wallet.nostrPaymentAddress);
    setPage(1)
    fetchData(mtid, wallet.nostrPaymentAddress, 1);
  }, [wallet.nostrPaymentAddress])

  const formatAddress = (value) => {
    return value.substring(0,6) + "..." + value.substring(value.length -4,);
  }

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value).then(
      () => {
        // Successfully copied to clipboard
        console.log('Address copied to clipboard');
        toast("Address copied!" , {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          className: 'my-toast'
          });
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy address: ', err);
      }
    );
  }

  return (
    <div>
      <Card className="w-full min-h-[830px] px-3">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-4 flex flex-col gap-2">
            <Typography variant="h4" color="blue-gray" className="text-center">
              MTID Mint
            </Typography>
            <div className = "flex md:flex-row flex-col gap-4 md:items-center items-end justify-center mt-4">
              <div className = "flex flex-row gap-2 items-center">
                <Typography variant="h6" color="blue-gray">
                  MTID:
                </Typography>
                <div className="max-w-[200px]">
                  <Input
                    label="Search"
                    icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                    value = {mtid}
                    onChange = {(e) => {handleSearch(0, e.target.value)}}
                  />
                </div>
              </div>
              <div className = "flex flex-row gap-2 items-center">
                <Typography variant="h6" color="blue-gray">
                  Owner:
                </Typography>
                <div className="max-w-[200px]">
                  <Input
                    label="Search"
                    icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                    value = {owner}
                    onChange = {(e) => {handleSearch(1, e.target.value)}}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody className=" overflow-scroll px-0 scrollbar-hidden">
          <table className="w-full min-w-max table-auto text-left border-[1px] border-solid border-gray">
            <thead>
              <tr className="h-[53px] max-h-[53px]">
                {TABLE_HEAD.map((head) => (
                  <th
                    key={uuidv4()}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {mintDatas.map(
                (
                  itme,
                  index,
                ) => {
                  const isLast = index === mintDatas.length - 1;
                  const classes = isLast
                    ? "p-4"
                    : "p-4 border-b border-blue-gray-50";
  
                  return (
                    <tr key={uuidv4()}>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {itme.t}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Tooltip content={`${itme.owner}`}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal cursor-pointer"
                            onClick={() => handleCopy(itme.owner)}
                          >
                            {formatAddress(itme.owner)}
                          </Typography>
                        </Tooltip>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {itme.count}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <div className="flex flex-row gap-2">
                          {
                            itme.path.split(",").map((item, index) => {
                              return <div key = {index} onClick={()=> {handleClick(item, itme.t)}}><Typography
                                variant="small"
                                color="blue-gray"
                                className="hover:text-blue-800 hover:font-bold cursor-pointer"
                              >
                                {item}
                              </Typography></div>
                              //return <div key={index} className=" hover:text-blue-800 hover:font-bold cursor-pointer" onClick={()=> {handleClick(item, itme.t)}}>{item}</div>
                            })
                          }
                        </div>
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-center">
          {
            total > 1 ? 
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="outlined"
                onClick={prev}
                disabled={page === 1}
              >
                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="gray" className="font-normal">
                Page <strong className="text-gray-900">{page}</strong> of{" "}
                <strong className="text-gray-900">{total}</strong>
              </Typography>
              <IconButton
                size="sm"
                variant="outlined"
                onClick={next}
                disabled={page === total}
              >
                <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
            :
            <></>
          }
          
        </CardFooter>
      </Card>
      <ToastContainer />
    </div>

  )
}
